import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { PricingComponent } from './pricing/pricing.component';
import { FeaturesComponent } from './features/features.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ThanksComponent } from './thanks/thanks.component';

const routes: Routes = [
   { path: '', component: LandingComponent },
   { path: 'get-started', component: GetStartedComponent },
   { path: 'pricing', component: PricingComponent },
   { path: 'features', component: FeaturesComponent },
   { path: 'terms-of-service', component: TermsOfServiceComponent },
   { path: 'privacy-policy', component: PrivacyPolicyComponent },
   { path: 'blog', loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule) },
   { path: 'thanks', component: ThanksComponent },
   { path: '**', redirectTo: '' },
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
