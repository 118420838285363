<div class="get-started-container translucent-background">
   <div class="header-text">Take the first step towards the next phase of your business.</div>
   <div class="get-started-desc">
      Ready to get started, or just interested in hearing more? Sign up below and we'll get in
      touch.
   </div>

   <mat-card>
      <mat-card-content>
         <ng-container *ngIf="submitted; else zohoContactForm">
            <div class="image-wrapper">
               <img class="mail-box" src="/assets/images/drawings/mail-box.svg" />
            </div>
            <div class="thank-you">Thank you for reaching out! We will contact you shortly.</div>
         </ng-container>
         <ng-template #contactForm>
            <form [formGroup]="form">
               <mat-form-field>
                  <input
                     matInput
                     type="email"
                     formControlName="email"
                     placeholder="Email"
                     required
                  />
               </mat-form-field>
               <mat-form-field>
                  <input
                     matInput
                     type="text"
                     formControlName="firstName"
                     placeholder="First Name"
                     required
                  />
               </mat-form-field>
               <mat-form-field>
                  <input matInput type="text" formControlName="lastName" placeholder="Last Name" />
               </mat-form-field>
               <mat-form-field>
                  <input matInput type="text" formControlName="phone" placeholder="Phone" />
               </mat-form-field>
               <mat-form-field>
                  <input matInput type="text" formControlName="company" placeholder="Company" />
               </mat-form-field>
               <mat-form-field>
                  <mat-label>Message</mat-label>
                  <textarea matInput formControlName="message"></textarea>
               </mat-form-field>
            </form>
         </ng-template>
         <ng-template #zohoContactForm>
            <!-- Note :    - You can modify the font style and form style to suit your website.    - Code lines with comments Do not remove this code are required for the form to work properly, make sure that you do not remove these lines of code.    - The Mandatory check script can modified as to suit your business needs.    - It is important that you test the modified form before going live.-->
            <div
               id="crmWebToEntityForm"
               class="zcwf_lblRight crmWebToEntityForm"
               style="background-color: white; color: black; max-width: 600px"
            >
               <form
                  id="webform914931000017300001"
                  action="https://crm.zoho.com/crm/WebToLeadForm"
                  name="WebToLeads914931000017300001"
                  method="POST"
                  accept-charset="UTF-8"
                  [formGroup]="form"
                  #zohoForm
               >
                  <input
                     type="text"
                     style="display: none"
                     name="xnQsjsdp"
                     value="f0c39ec3df46eb8c5865741380b95b202a0d1ad128ae672428848e9bb33aeff1"
                  />
                  <input type="hidden" name="zc_gad" id="zc_gad" value="" />
                  <input
                     type="text"
                     style="display: none"
                     name="xmIwtLD"
                     value="f62a4446518ef46babf32d918ad2e504701509ba38a7b385f97545d2d78b19d56e786b923462e911ad411d791d0b7125"
                  />
                  <input type="text" style="display: none" name="actionType" value="TGVhZHM=" />
                  <input
                     type="text"
                     style="display: none"
                     name="returnURL"
                     value="https&#x3a;&#x2f;&#x2f;trellistools.com/thanks"
                  />
                  <!-- Do not remove this code. -->

                  <mat-form-field>
                     <input
                        matInput
                        type="text"
                        id="First_Name"
                        formControlName="firstName"
                        placeholder="First Name"
                        required
                        aria-required="true"
                        aria-label="First Name"
                        name="First Name"
                        aria-valuemax="40"
                        maxlength="40"
                     />
                  </mat-form-field>
                  <mat-form-field>
                     <input
                        matInput
                        required
                        type="text"
                        formControlName="lastName"
                        placeholder="Last Name"
                        id="Last_Name"
                        aria-required="true"
                        aria-label="Last Name"
                        name="Last Name"
                        aria-valuemax="80"
                        maxlength="80"
                     />
                  </mat-form-field>
                  <mat-form-field>
                     <input
                        matInput
                        type="email"
                        formControlName="email"
                        placeholder="Email"
                        required
                        ftype="email"
                        autocomplete="false"
                        id="Email"
                        aria-required="true"
                        aria-label="Email"
                        name="Email"
                        aria-valuemax="100"
                        crmlabel=""
                        maxlength="100"
                     />
                  </mat-form-field>
                  <mat-form-field>
                     <input
                        matInput
                        required
                        type="text"
                        formControlName="phone"
                        placeholder="Phone"
                        id="Phone"
                        aria-required="true"
                        aria-label="Phone"
                        name="Phone"
                        aria-valuemax="30"
                        maxlength="30"
                     />
                  </mat-form-field>
                  <mat-form-field>
                     <input
                        matInput
                        type="text"
                        formControlName="company"
                        placeholder="Company"
                        id="Company"
                        aria-required="false"
                        aria-label="Company"
                        name="Company"
                        aria-valuemax="200"
                        maxlength="200"
                     />
                  </mat-form-field>
                  <mat-form-field>
                     <mat-label>Message</mat-label>
                     <textarea
                        matInput
                        formControlName="message"
                        aria-multiline="true"
                        id="LEADCF2"
                        aria-required="false"
                        aria-label="LEADCF2"
                        name="LEADCF2"
                     ></textarea>
                  </mat-form-field>
                  <!-- Do not remove this --- Analytics Tracking code starts -->
                  <script
                     id="wf_anal"
                     src="https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=0caa4df2cfe4614ca9271ec1af8184f00655ae7c99dfe6fd5309d80356e508cec8a6eaae98c4506c09c54d68cfd2298fgid6bf2822689748d8c3805ae45809c49dbc9d93fcfc30bd9e98915e27199c2b546gid398fa117ce1f35d2dc4c0153cd7596e7058a666b8378e123a8de8ec21b16c81fgid10488d11b8e88857334fde2df66188d746b861882c440557fc2430235caa17ec&tw=cd84931b8f5661ce2e616c5066d38a8c78ec9ed71b2a2bdb44ba05968e9a37c6"
                  ></script>
                  <!-- Do not remove this --- Analytics Tracking code ends. -->
               </form>
            </div>
         </ng-template>
      </mat-card-content>
      <mat-card-actions *ngIf="!submitted">
         <button mat-flat-button color="primary" [disabled]="!form.valid" (click)="submit()">
            Submit
         </button>
         <button
            mat-stroked-button
            color="primary"
            [disabled]="form.pristine"
            (click)="form.reset()"
         >
            Reset
         </button>
      </mat-card-actions>
      <mat-spinner *ngIf="sendingEmail" color="primary"></mat-spinner>
   </mat-card>
</div>
