import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

declare var Email: any;

@Component({
   selector: 'app-get-started',
   templateUrl: './get-started.component.html',
   styleUrls: ['./get-started.component.scss'],
})
export class GetStartedComponent implements OnInit {
   @ViewChild('zohoForm') zohoForm: ElementRef<HTMLFormElement>;

   form: FormGroup;
   submitted = false;
   sendingEmail = false;

   constructor() {}

   ngOnInit() {
      this.form = new FormGroup({
         firstName: new FormControl(null, [Validators.required]),
         lastName: new FormControl(null, [Validators.required]),
         email: new FormControl(null, [Validators.email, Validators.required]),
         company: new FormControl(),
         message: new FormControl(),
         phone: new FormControl(null, [Validators.required]),
      });
   }

   submit() {
      this.zohoForm.nativeElement.submit();
      this.sendingEmail = true;
   }
}
