<!-- <mat-card>
   <mat-card-content>
      <div class="image-wrapper">
         <img class="mail-box" src="/assets/images/drawings/mail-box.svg" />
      </div>
      <div class="thank-you">Thank you for reaching out! We will contact you shortly.</div>
   </mat-card-content>
</mat-card> -->

<div class="thanks-container translucent-background">
   <div class="header-text">Thanks for reaching out!</div>
   <div class="image-wrapper">
      <img class="mail-box" src="/assets/images/drawings/mail-box.svg" />
   </div>
   <div class="thanks-desc">We will contact you shortly.</div>
</div>
